import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./Phone.settingsForm";

export default class Phone extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Phone Number",
      icon: "phone-square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Phone.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "phoneCustomComp",
      phoneLabel: "",
      labelAlign: "text-left",
      colorTheme: "bg-white",
    });
  }

  static editForm = settingsForm;

  validatePhone(value) {
    var regex = /^\d{10}$/;
    var is_validated = regex.test(value);
    if (value == ""){
      is_validated = true;
    }
    if (is_validated){
      $(`input#${this.component.key}-phoneNumberInput`).removeClass("has-error");
      $(`input#${this.component.key}-phoneNumberInput`).closest(".formio-component-phoneCustomComp").removeClass("has-error");
      $(`input#${this.component.key}-phoneNumberInput`).closest(".formio-component-phoneCustomComp").find(".formio-errors").html("");
      return value;
    }
    else{
      $(`input#${this.component.key}-phoneNumberInput`).addClass("has-error");
      $(`input#${this.component.key}-phoneNumberInput`).closest(".formio-component-phoneCustomComp").addClass("has-error");
      $(`input#${this.component.key}-phoneNumberInput`).closest(".formio-component-phoneCustomComp").find(".formio-errors").html("Phone Number must be a valid phone.");
      return "";
    }
  }

  render(children) {

    $(".component-edit-container p.lead").html("Phone Number");

    let phone_label = "";
    phone_label += `<span class="comp-label">`;
    phone_label += `${this.component.phoneLabel || ''}`;
    phone_label += `</span>`;

    var phoneNumberInput = ''
    var phoneNumberInput = this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `${this.component.key}-phoneNumberInput`,
        attr: {
          id: `${this.component.key}-phoneNumberInput`,
          class: 'line-input w-100',
          // type: 'phoneNumber',
          type: 'phoneNumber',
          maxlength: "10",
          placeholder: "(___) ___-____"
        }
      }
    });

    return super.render(`
      <div id="${this.component.key}" class="${this.component.colorTheme}">
        <div class="${this.component.labelAlign}">
          ${phone_label}
        </div>
        <div class="form-group">
          ${phoneNumberInput}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`${this.component.key}-phoneNumberInput`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`${this.component.key}-phoneNumberInput`][0], 'keyup', () => this.updateValue())
    
    return super.attach(element);
  }

  getValue() {
    var value = '';
    value = this.refs[`${this.component.key}-phoneNumberInput`][0].value;

    var new_value = this.validatePhone(value);

    // var new_value = value.replace(/[^0-9\.]/g, '');

    // var value_0 = new_value[0] ? new_value[0] : '_';
    // var value_1 = new_value[1] ? new_value[1] : '_';
    // var value_2 = new_value[2] ? new_value[2] : '_';
    // var value_3 = new_value[3] ? new_value[3] : '_';
    // var value_4 = new_value[4] ? new_value[4] : '_';
    // var value_5 = new_value[5] ? new_value[5] : '_';
    // var value_6 = new_value[6] ? new_value[6] : '_';
    // var value_7 = new_value[7] ? new_value[7] : '_';
    // var value_8 = new_value[8] ? new_value[8] : '_';
    // var value_9 = new_value[9] ? new_value[9] : '_';


    // new_value = `(${value_0}${value_1}${value_2}) ${value_3}${value_4}${value_5}-${value_6}${value_7}${value_8}${value_9}`;

    // this.refs[`${this.component.key}-phoneNumberInput`][0].value = new_value;

    // if (value.length > 10){
    //   this.refs[`${this.component.key}-phoneNumberInput`][0].value = value.slice(0, 10);
    // }
    $(`input#${this.component.key}-phoneNumberInput`).attr("value", new_value);
    // $(`input#${this.component.key}-phoneNumberInput`).setCursorPosition(4);
    return new_value;
  }

  setValue(value) {
    this.refs[`${this.component.key}-phoneNumberInput`][0].value = value;
    $(`input#${this.component.key}-phoneNumberInput`).attr("value", value);
  }

}
