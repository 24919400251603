import { Button } from "antd";
import { AudioMutedOutlined, AudioOutlined } from "@ant-design/icons";
import MicrophoneStream from "microphone-stream";
import React, { useCallback, useEffect, useState } from "react";

import { AudioStreamer } from "./audiostreamer";
import { audioBucket } from "./audiobucket";
import { KaldiRecognizer } from "vosk-browser";
import MicImage from 'images/mic.svg'
import MicGreenImage from 'images/mic-green.svg'
import $ from 'jquery';


interface Props {
  recognizer: KaldiRecognizer | undefined;
  ready: boolean;
  loading: boolean;
}

interface VoskResult {
  result: Array<{
    conf: number;
    start: number;
    end: number;
    word: string;
  }>;
  text: string;
}

let micStream: any;
let audioStreamer: AudioStreamer;

const Microphone: React.FunctionComponent<Props> = ({
  recognizer,
  loading,
  ready,
  setPartial,
  setUtterances
}) => {
  const [muted, setMuted] = useState(true);

  const startRecognitionStream = useCallback(async () => {
    if (recognizer) {
      setMuted(true);

      if (!micStream) {
        let mediaStream = null;
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
            },
          });

          micStream = new MicrophoneStream({
            objectMode: true,
            bufferSize: 512,
          });

          micStream.setStream(mediaStream);
        } catch (err) {
          console.error(err);
        }
      } else {
        micStream.unpipe(audioStreamer);
        micStream.pipe(audioBucket);
      }

      audioStreamer = new AudioStreamer(recognizer, {
        objectMode: true,
      });
    }
  }, [recognizer]);

  useEffect(() => {
    startRecognitionStream();
  }, [recognizer]);

  useEffect(() => {
    setMuted(true);
  }, [loading]);

  useEffect(() => {
    if (!muted) {
      micStream?.unpipe(audioBucket);
      micStream?.pipe(audioStreamer);
    } else {
      micStream?.unpipe(audioStreamer);
      micStream?.pipe(audioBucket);
    }
  }, [muted]);

  const toggleMic = () => {

    console.log("toggleMic");
    setPartial('');
    setUtterances((utt: VoskResult[]) => []);
    $('#speechContainerDiv span').html('');
    $('#speechContainerDiv span:first').html('');
    
    setMuted((muted) => !muted);

  };

  return (
    <div className= ''>
      <div
        type='button'
        className=''
        disabled={!ready || loading}
        onClick={toggleMic}
        > <img  className={`img-speech ${muted ? 'anticon-audio-muted' : 'anticon-audio'} ${loading ? "disabled" : ""}`} src={muted ? MicImage :
          MicGreenImage } />
          <span className={` ${muted ? '' : 'introjs-hint-pulse'}`}></span>
          <div style={{fontsize: "8px", position: "relative", right: '-20px', top: '-9px', display: 'none'}}>{loading ? "Loading..." : ""}</div>

      </div>

    </div>
  );
};

export default Microphone;
