import React, { useState } from "react";
import styled from "styled-components";

import { createModel, KaldiRecognizer, Model } from "vosk-browser";
import Microphone from "./microphone";
import ModelLoader from "./model-loader";
import CreateForm from "./CreateForm";
import $ from 'jquery';

const Wrapper = styled.div`
  width: 80%;
  text-align: left;
  max-width: 700px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto;
`;

const ResultContainer = styled.div`
  width: 100%;
  // margin: 1rem auto;
  // border: 1px solid #aaaaaa;
  // padding: 1rem;
  // resize: vertical;
  // overflow: auto;
`;

let  TextInputContent = "";



const Word = styled.span<{ confidence: number }>`
  color: ${({ confidence }) => {
    const color = Math.max(255 * (1 - confidence) - 20, 0);
    return `rgb(${color},${color},${color})`;
  }};
  white-space: normal;
`;

interface VoskResult {
  result: Array<{
    conf: number;
    start: number;
    end: number;
    word: string;
  }>;
  text: string;
}

export const Recognizer: React.FunctionComponent = () => {

  const [utterances, setUtterances] = useState<VoskResult[]>([]);
  const [partial, setPartial] = useState("");
  const [loadedModel, setLoadedModel] = useState<{
    model: Model;
    path: string;
  }>();
  const [recognizer, setRecognizer] = useState<KaldiRecognizer>();
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [formData, setFormData] = useState({
      title: "",
      body: ""
    })

  const loadModel = async (path: string) => {
    setLoading(true);
    loadedModel?.model.terminate();

    const model = await createModel(process.env.PUBLIC_URL + "/models/" + path);

    setLoadedModel({ model, path });
    const recognizer = new model.KaldiRecognizer(48000);
    recognizer.setWords(true);
    recognizer.on("result", (message: any) => {
      const result: VoskResult = message.result;
      console.log("recognizer result:", result)
      if(result && result.text.length > 0) {
        insertTextToForm(result.text);
        $("#last-pressed-key").val("speech");
      }
      setUtterances((utt: VoskResult[]) => [...utt, result]);
    });

    recognizer.on("partialresult", (message: any) => {
      if(message.result && message.result.partial.length > 0 ) {
        //insertTextToForm( message.result.partial);
          // var txtarea = document.getElementById('textAreaCustomComp-textFieldMultipleLines');
          // //Outside the React scope
          // var event = new Event('keyup', { bubbles: true});
          // //Toggling simulated=false or true and will not trigger the value change
          // event.simulated = true;
          // txtarea.value = message.result.partial;
          if ($('#speech-input-focus').attr('data-focus') == 'true'){
            $("#speechContainer").fadeIn("fast");
          }
      } 
       setPartial(message.result.partial);

    });

    setRecognizer(() => {
      setLoading(false);
      setReady(true);
      return recognizer;
    });
  };

   const withPunctuation = (word_array: any) => {
      //insertTextToForm();
      switch(word_array.word) {
        case 'period':
          return '.';
        case 'comma':
          return ',';
        case 'exclamation':
          return '!';
        case 'questionmark':
          return '?';
        case 'question':
          return '?';
        case 'quote':
          return '"';
        case 'quotes':
          return '"';
        default:
          return word_array.word;
      }
    };

  const handleChange = (e) => {
        console.log('handleChange',e);
        // this.props.onChange(e);
    };
  return (
    <Wrapper>
      <ModelLoader
        onModelChange={(path) => setReady(loadedModel?.path === path)}
        onModelSelect={(path) => {
          if (loadedModel?.path !== path) {
            loadModel(path);
          }
        }}
        loading={loading}
      />
      <Header>
        <Microphone recognizer={recognizer} loading={loading} ready={ready} setPartial={setPartial} setUtterances={setUtterances} />
      </Header>
      <div id="speechContainer">
      <ResultContainer id="speechContainerDiv" className='d-none122 mirror'>
        {utterances.map((utt, uindex) =>
          utt?.result?.map((word, windex) => (
            <Word
              key={`${uindex}-${windex}`}
              confidence={word.conf}
              title={`Confidence: ${(word.conf * 100).toFixed(2)}%`}
              className='d-none'
            > 
              {withPunctuation(word)}{" "}
            </Word>
          ))
        )}
        <span key="partial" className='mirror' id="partial-result" style={{color: 'gey'}}>{partial}</span>
      </ResultContainer>


      </div>
    

    </Wrapper>
  );
};
